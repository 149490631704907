/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Smart } from 'smart-webcomponents-react/grid';

/** Components */
import Loader from './../../Loader/Loader';

/** Helpers */
import { isNullOrUndefined, isValidJSON } from './../../../helpers/functions.js';
import { filterDatas } from './../../../helpers/filters.js';
import { callWebservice } from './../../../helpers/webservice/webserviceCaller';

/** JSON */
import i18n from './../../../assets/json/i18n.json';

/** SCSS */
import './GridCore.scss';

function GridCore( props )
{
  /** Get props */
  const {
    coreType,
    gridColumns,
    dataFields,
    loadingParams,
    defaultVisibleColumns,
    displayMode,
    gridSelectedKeywordsGroups,
    formatDataSourceFct,
    getResultsFct,
    callBackFct,
    pageSize,
    columnHeight,
    rowHeight,
    pagerHeight,
    visibleHeader,
    pageSizeSelector,
    checkboxesSelection,
    editing,
    onEndEditFct,
    reloadTrigger
  } = props;

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Init State */
  const [ dataSource, setDataSource ] = useState( null );
  const [ dataSourceFiltered, setDataSourceFiltered ] = useState( null );
  const [ selectedKeywords, setSelectedKeywords ] = useState( null );
  const [ gridComponent, setGridComponent ] = useState( null );
  const [ gridComponentLoaded, setGridComponentLoaded ] = useState( false );
  const [ gridComponentLoading, setGridComponentLoading ] = useState( false );
  const [ currentScrollY, setCurrentScrollY ] = useState( 0 );
  const [ reloadGridRandomIndex, setReloadGridRandomIndex ] = useState( null );

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  
  /** Return current column height with screen size */
  const getCurrentColumnHeight = () => gridComponent !== null ? 
    !isNullOrUndefined( rowHeight ) ?
      rowHeight
      : ( gridComponent.offsetHeight - columnHeight - pagerHeight ) / pageSize
    : 'auto';

  /** Update checkboxes */
  const updateCheckboxes = keywords => 
  {
    if( 
      gridComponent !== null
      && Array.isArray( keywords ) 
      && keywords.length > 0 
    ){      
      // get selected rows from group
      const selectedRowsIndexesFromGroups = dataSource.map( ( elem, index ) => 
        keywords.includes( elem.keywords[0] ) ? 
          index 
          : null 
      ).filter( elem => 
        elem !== null 
      );

      // clear selection
      gridComponent.clearSelection();

      // Set allowRowSelection to true to use select method
      gridComponent.selection.allowRowSelection = true;

      // select rows from group (use select method instead of selectRows because this one doesn't fire change event )
      selectedRowsIndexesFromGroups.forEach( rowId => gridComponent.select( rowId ) );

      // set allowRowSelection to false to stuck selection on row
      gridComponent.selection.allowRowSelection = false;
    }
  }

  /** Set random index for reload grid */
  const reloadGrid = () => setReloadGridRandomIndex( Math.ceil( Math.random() * 1000 ) );

  /** Init Grid Component */
  useEffect( () => 
  {
    // Init and append Grid component only if grid component container is not append :: Use for dev when React.StrictMode is true
    if( isNullOrUndefined( document.getElementById( 'grid-component-' + coreType ) ) )
    {
      // create smart grid container element
      const grid = document.createElement( 'div' );
      grid.setAttribute( 'id', 'grid-component-' + coreType );
      grid.setAttribute( 'class', 'grid-' + coreType );
  
      // append smart grid container element sibling to loader
      document.getElementById( 'loader-grid-loader-' + coreType ).parentElement.appendChild( grid );

      // instance new grid component
      const gridComponentObject = new Smart.Grid( '#grid-component-' + coreType, {
        columns: gridColumns,
        dataSourceSettings:{
          dataFields: dataFields
        },
        appearance:{
          showColumnLines: false,
          allowHover: true
        },
        header:{
          visible: visibleHeader ? visibleHeader : false,
          buttons: [ 'columns' ]
        },
        sorting:{
          enabled: true
        },
        selection:{
          enabled: true,
          defaultSelection: true,
          allowRowSelection: false,
          checkBoxes: {
            enabled: !isNullOrUndefined( checkboxesSelection ) ? checkboxesSelection : false,
            position: 'far',
            selectAllMode: 'all'
          }
        },
        editing: {
          enabled: !isNullOrUndefined( editing ) ? editing : false
        },
        locale: "fr",
        messages: i18n,
        layout:{
          columnHeight: columnHeight,
          autoHeight: true
        },
        pager:{
          visible: true,
          pageSizeSelector: {
            visible: pageSizeSelector !== undefined ? pageSizeSelector : false,
            dataSource: [ 25, 50, 100 ]
          },
          pageIndexSelectors:{
            dataSource: 16
          }
        },
        paging:{
          enabled: true,
          pageSize: pageSize,
          // pageHierarchySize: 2
        },
        onLoad: setGridComponentLoaded( true )
      });

      // set grid Component
      setGridComponent( gridComponentObject );
    }

  }, []);

  /** Load Datas Source */
  useEffect( () => 
  {
    if( 
      loadingParams.currentPeriod !== null
      && loadingParams.comparePeriod !== null
    ){
      // set grid component loading to true
      setGridComponentLoading( true );

      // clear selected keywords
      setSelectedKeywords( null );

      // load data source
      callWebservice(
        'grid-loader-' + coreType,
        'grid-loader-' + coreType,
        'data-grid',
        dispatch,
        loadingParams.clientID,
        {
          context: coreType,
          period: loadingParams.period,
          where: loadingParams.where,
          userId: loadingParams.userId,
          dataFields: loadingParams.dataFieldsToLoad
        },
        {
          function: 'formatFilterAndSetDataSource'
        }      
      );
    }

  }, [ 
    JSON.stringify( loadingParams ), 
    reloadGridRandomIndex 
  ]);

  /** Update Grid Component */
  useEffect( () => 
  {
    if( dataSource !== null && gridComponentLoaded )
    {
      // filter data source
      const dataSourceFiltered = filterDatas( dataSource, loadingParams.filters );

      // set data source filtered
      setDataSourceFiltered( dataSourceFiltered );

      // Add childrenDatafield preperty if subitems exists
      if( dataSourceFiltered.filter( elem => elem?.subitems && elem.subitems.length > 0 ).length > 0 )
        gridComponent.dataSourceSettings.childrenDataField = 'subitems' ;

      // update data source of Grid component
      gridComponent.dataSource = []; // reset dataSource before updating else it doesn't work
      gridComponent.dataSource = dataSourceFiltered;

      // update row sum in label header column
      let labelColIndex = null;
      if( Array.isArray( gridColumns ) )
        labelColIndex = gridColumns.findIndex( elem => elem.dataField === 'label' );

      if( !isNullOrUndefined( labelColIndex ) && coreType !== 'admin-keywords' && coreType !== 'admin-users' ){        
        gridComponent.setColumnProperty( 
          gridColumns[labelColIndex].dataField, 
          gridColumns[labelColIndex].dataField, 
          gridColumns[labelColIndex].label + ' (' + dataSourceFiltered.length + ')' 
        );
      }

      // update row height
      gridComponent.layout.rowHeight = getCurrentColumnHeight();
      
      // update checkboxes with labels
      updateCheckboxes( selectedKeywords );
    }

  }, [ 
    dataSource, 
    gridComponentLoaded
  ]);

  /** Call callback function with data source filtered / non filtered / current and compare period */
  useEffect( () => 
  {
    if( 
      typeof callBackFct === 'function'
      && dataSource !== null
      && dataSourceFiltered !== null
    )
      callBackFct({ 
        filteredDataSource: dataSourceFiltered, 
        nonFilteredDataSource: dataSource,
        selectedKeywords: selectedKeywords,
        gridComponentLoading: gridComponentLoading
      });
    
  }, [
    dataSourceFiltered,
    selectedKeywords,
    gridComponentLoading
  ]);

  /** Display or hide columns with display mode */
  useEffect( () => 
  {
    if( 
      gridComponentLoaded 
      && !isNullOrUndefined( gridComponent?.columns[0]?.dataField ) 
      && !isNullOrUndefined( defaultVisibleColumns ) 
    ){
      if( displayMode === 'full-grid' )
      {
        // display columns for full grid mode
        gridComponent.columns.forEach( column => 
          gridComponent.setColumnProperty( column.dataField, 'visible', true ) 
        );
  
        // update width of label column
        gridComponent.setColumnProperty( gridComponent.columns[0].dataField, 'width', '25%' );
  
      } else if( displayMode === 'chart-grid' ) {
  
        // display and hide columns for chart-grid mode
        gridComponent.columns.forEach( column => 
          defaultVisibleColumns.includes( column.dataField ) ? 
            gridComponent.setColumnProperty( column.dataField, 'visible', true )
            : gridComponent.setColumnProperty( column.dataField, 'visible', false ) 
        );
        
        // update width of label column
        gridComponent.setColumnProperty( gridComponent.columns[0].dataField, 'width', '40%' );
      }
    }    
    
  }, [ 
    displayMode, 
    gridComponentLoaded, 
    gridComponent 
  ]);

  /** Add events listeners */
  useEffect( () => 
  {
    if( gridComponentLoaded )
    {
      /** Update row height with window size */
      const updateRowHeight = () => gridComponent.layout.rowHeight = getCurrentColumnHeight();

      /** Update current scrollY position */
      const updateCurrentScrollY = () => setCurrentScrollY( window.scrollY );

      /** Update selected keywords with selected rows */
      const updateSelectedRows = e => 
        e?.detail?.originalEvent?.target?.dataset?.field === '_checkBoxColumn' 
        || e?.detail?.originalEvent?.target?.children[0]?.dataset?.field === '_checkBoxColumn' ? 
          setSelectedKeywords( gridComponent.getSelectedRows().map( elem => isValidJSON( elem[1].label ) ? 
            JSON.parse( elem[1].label ).value
            : elem[1].label
          ))
          : null

      /** Update all checkboxes with global checkbox */
      const updateAllCheckboxes = e => e.detail.column.dataField === '_checkBoxColumn' ? 
        setSelectedKeywords( gridComponent.getSelectedRows().map( elem => isValidJSON( elem[1].label ) ?
          JSON.parse( elem[1].label ).value
          : elem[1].label
        )) 
        : null;

      /** call function after edit ending */
      const editListener = e => onEndEditFct( e );

      // add event listener on screen resize to resize row height
      window.addEventListener( 'resize', updateRowHeight );

      // add event listener on scroll to save current scrollY
      window.addEventListener( 'scroll', updateCurrentScrollY );

      // add event listener on row click
      gridComponent.addEventListener( 'rowClick', updateSelectedRows );

      // add event listener on checkbox column click
      gridComponent.addEventListener( 'columnClick', updateAllCheckboxes );

      // add end edit event
      gridComponent.addEventListener( 'endEdit', editListener );

      return () => {
        window.removeEventListener( 'resize', updateRowHeight );
        window.removeEventListener( 'scroll', updateCurrentScrollY );
        gridComponent.removeEventListener( 'rowClick', updateSelectedRows );
        gridComponent.removeEventListener( 'columnClick', updateAllCheckboxes );
        gridComponent.removeEventListener( 'endEdit', editListener );        
      };
    }
  
  }, [ 
    gridComponentLoaded, 
    loadingParams 
  ]);

  /** Scroll to current scrollY after set focus on Grid element */
  useEffect( () =>
  {
    if( gridComponentLoaded )
    {
      /** Scroll to currentScrollY */
      const scrollToCurrentScrollY = () => window.scroll( 0, currentScrollY );

      // add event listener on grid when focus is setted
      gridComponent.addEventListener( 'focus', scrollToCurrentScrollY );
    
      return () => 
      {
        gridComponent.addEventListener( 'focus', scrollToCurrentScrollY );
      };
    }

  }, [
    gridComponentLoaded,
    currentScrollY 
  ]);

  /** Select rows with keywords group */
  useEffect( () => 
  {
    if( 
      Array.isArray( gridSelectedKeywordsGroups ) 
      && gridSelectedKeywordsGroups.length > 0 
    ){
      // filter selected keywords with current grid keywords
      const filteredGridSelectedKeywordsGroups = gridSelectedKeywordsGroups.filter( elem => 
        dataSource.map( elem => elem.keywords ).flat().includes( elem ) 
      );

      if( filteredGridSelectedKeywordsGroups.length > 0 )
      {
        // set selected keywords
        setSelectedKeywords( filteredGridSelectedKeywordsGroups );
  
        // update checkboxes
        updateCheckboxes( filteredGridSelectedKeywordsGroups );

      } else {

        // reset selected keywords
        setSelectedKeywords( [] );

        // update checkboxes
        updateCheckboxes( [] );
      }
    }

  }, [ 
    gridSelectedKeywordsGroups,
    dataSource,
    loadingParams.location
  ]);

  /** Reset grid component loaded to false when core type grid has changed */
  useEffect( () => 
  { 
    if( gridComponentLoaded ) 
      setGridComponentLoaded( false );

  }, [ coreType ]);

  /** Reload Grid if reload trigger has changed */
  useEffect( () => 
  {
    if( !isNullOrUndefined( reloadTrigger ) )
      reloadGrid();

  }, [ reloadTrigger ] );

  return(
    <React.Fragment>

      {/* Loader */}
      <Loader 
        loaderID={ 'grid-loader-' + coreType }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          formatFilterAndSetDataSource: results => 
          {
            // reset grid component loading to false
            setGridComponentLoading( false );

            // set data source with format results
            setDataSource( formatDataSourceFct( results ) )
          },
          reloadGrid: reloadGrid,
          getResults: results => getResultsFct( results )
        }}
      />

    </React.Fragment>
  )
}

export default GridCore;