/** Components */
import WindowButton from './../../WindowButton/WindowButton';
import AdminMenuContextualAddUsers from './../AdminMenuContextualContent/AdminMenuContextualAddUsers';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';

/** SCSS */
import './AdminMenuContextualUsers.scss';

function AdminMenuContextualUsers( props )
{
  const {
    params,
    updateReloadTrigger,
  } = props;

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  return(
    <div className='contextual-options users'>

      {/* Keywords add button */}
      <WindowButton 
        id="users-add-window"
        className="users-add-window"
        activeEffect="horizontalFlip"
        buttonContent={ getPicto( 'Plus', { size: '1.5rem', fill: grey2Color } ) }
        windowContent={ 
          <AdminMenuContextualAddUsers 
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        }
        width={ 400 }
        height={ window.innerHeight - 144 }
      />

    </div>
  )
}

export default AdminMenuContextualUsers;