/** Dependencies */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, generatePath, useSearchParams } from "react-router-dom";

/** Components */
import Loader from './../../Loader/Loader';
import AdminMenuContextualKeywords from './../AdminMenuContextual/AdminMenuContextualKeywords';
import AdminMenuContextualUsers from './../AdminMenuContextual/AdminMenuContextualUsers';
import AdminMenuContextualCategories from './../AdminMenuContextual/AdminMenuContextualCategories';

/** Helpers */
import { getInstanceDatas } from './../../../helpers/instance';
import { isNullOrUndefined } from '../../../helpers/functions';

/** Object class */
import wsKeywords from './../../../helpers/webservice/wsKeywords.class';
import wsUsers from './../../../helpers/webservice/wsUsers.class';

/** SCSS */
import './AdminMenu.scss'

function AdminMenu( props )
{
  const {
    context,
    params,
    updateReloadTrigger
  } = props;
  
  /** Instance useNavigate Hook */
  const navigate = useNavigate();

  /** Instance useDispatch Hook **/
  const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Instance useSearchParams Hook */
  let [ searchParams ] = useSearchParams();

  /** Get instance infos from url */
  const { clientID, location, device } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  /** Get role */
  const roleId = userDatas.enabled_instances.find( elem => elem.nickname === clientID ).roleId

  /** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Init useState Hook */
  const [ keywordsCount, setKeywordsCount ] = useState( null );
  const [ usersCount, setUsersCount ] = useState( null );

  /** Load indicators */
  useEffect( () => 
  {
    // load keywords count
    new wsKeywords(
      'admin-menu',
      device,
      location + '|' + city,
      dispatch,
      clientID
    ).get( 
      'setKeywordsCount'
    );

    // load users count
    new wsUsers(
      'admin-menu',
      dispatch,
      clientID
    ).get( 
      roleId,
      null,
      'setUsersCount'
    );
    
  }, [
    device,
    location
  ]);

  return(
    <div className='admin-menu-container'>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-menu' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setKeywordsCount: results => setKeywordsCount( [...new Set( results.map( keyword => keyword.keyword ) )].length ),
          setUsersCount: results => setUsersCount( results.length )
        }}
      />

      <ul className='menu-container'>
        <li 
          className={ context === 'keywords' ? 'selected' : '' }
          onClick={ () => navigate({
              pathname: generatePath( 
                '/admin/keywords/:clientID/:location/:device', 
                { clientID: clientID, location: location, device: device }
              ),
              search: searchParams.toString()
            })
          }
        >
          <div className='label'>Mots-clés</div>
          <div className='count'>{ !isNullOrUndefined( keywordsCount ) ? keywordsCount + ' / ' + keywordsCount : '- / -' }</div>
        </li>
        <li
          className={ context === 'categories' ? 'selected' : '' }
          onClick={ () => navigate({
            pathname: generatePath( 
              '/admin/categories/:clientID/:location/:device', 
              { clientID: clientID, location: location, device: device }
            ),
            search: searchParams.toString()
          })
        }
        >
          <div className='label'>Catégories</div>
        </li>
        <li
          className={ context === 'users' ? 'selected' : '' }
          onClick={ () => navigate({
              pathname: generatePath( 
                '/admin/users/:clientID/:location/:device', 
                { clientID: clientID, location: location, device: device }
              ),
              search: searchParams.toString()
            })
          }
        >
          <div className='label'>Utilisateurs</div>
          <div className='count'>{ !isNullOrUndefined( usersCount ) ? usersCount : '-' }</div>
        </li>
      </ul>
      {
        context === 'keywords' ?
          <AdminMenuContextualKeywords
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        : context === 'categories' ? 
          <AdminMenuContextualCategories
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        : context === 'users' ?
          <AdminMenuContextualUsers
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        : void(0)          
      }
    </div>
  )
}

export default AdminMenu;