/** Dependencies */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

/** Components */
import GridCore from './../GridCore.js';
import GridCellRemove from './../../Cells/GridCellRemove';
import GridCellResetPassword from './../../Cells/GridCellResetPassword';
import GridCellDropDownMenu from './../../Cells/GridCellDropDownMenu';

/** Helpers */
import { getInstanceDatas } from './../../../../helpers/instance';
import { 
  isNullOrUndefined,
  isValidJSON
} from './../../../../helpers/functions.js';

/** Services */
import { getItem } from './../../../../services/LocaleStorage.js';

/** Object class */
import wsUsers from './../../../../helpers/webservice/wsUsers.class';

/** SCSS */
import './GridCoreAdminUsers.scss';

function GridCoreAdminUsers( props )
{
  /** Get props */
  const {
    loadingParams,
    reloadTrigger,
    callBackFct
  } = props;

  /** Get user id */
  const userId = getItem( 'userId' );

  /** useDispatch hook **/
	const dispatch = useDispatch();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID } = useParams();

  // get instance datas from user datas
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  /** Init State */
  const [ reloadTriggerGrid, setReloadTriggerGrid ] = useState( null );
  
  /** Define page size */
  const pageSize = 15;

  /** Get role */
  const roleId = userDatas.enabled_instances.find( elem => elem.nickname === clientID ).roleId

  /** Define data fields */
  const dataFields = [
    { name: 'userId', dataType: 'int' },
    { name: 'picture', dataType: 'string' },
    { name: 'firstName', dataType: 'string' },
    { name: 'lastName', dataType: 'string' },
    { name: 'email', dataType: 'string' },
    { name: 'role', dataType: 'int' },
    { name: 'lastLoginDate', dataType: 'date' },  
    { name: 'resetPassword', dataType: 'string' },
    { name: 'remove', dataType: 'string' }
  ];

  /** Define grid columns properties */
  const gridColumns = [
    {
      label: '',
      dataField: 'userId',
      visible: false
    },{ 
      label: '',
      dataField: 'picture',
      width: 74,
      allowEdit: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = isValidJSON( formatObject.value ) ?
          !JSON.parse( formatObject.value ).picture.includes( 'default.jpg' ) ?
            '<img src="' + JSON.parse( formatObject.value ).picture + '" alt="" title="" />'
            : '<div class="paraf">' + JSON.parse( formatObject.value ).firstName.charAt( 0 ) + JSON.parse( formatObject.value ).lastName.charAt( 0 ) + '</div>'
        : ''
      }
    },{ 
      label: 'Prénom', 
      dataField: 'firstName',
      align: 'left',
      width: 150,
      cellsWrap: true
    },{ 
      label: 'Nom', 
      dataField: 'lastName',
      align: 'left',
      width: 150,
      cellsWrap: true
    },{ 
      label: 'Mail', 
      dataField: 'email',
      align: 'left',
      width: 270,
      allowEdit: false,
      cellsWrap: true
    },{ 
      label: 'Fonction', 
      dataField: 'role',
      align: 'left',
      width: 270,
      allowEdit: false,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellDropDownMenu({
          dataSource: roleId !== 1 ? 
            userDatas.roles.filter( role => role.id !== 1 )
            : userDatas.roles,
          currentValue: userDatas.roles.find( elem => elem.id === formatObject.value ),
          callBackFct: ( result ) => updateRole( 
            formatObject.row.data.userId, 
            userDatas.roles.find( elem => elem.id === result ).id
          )
        });
      }
    },{ 
      label: 'Denière connexion', 
      dataField: 'lastLoginDate',
      align: 'left',
      cellsFormat: 'dd/MM/yyyy HH:mm:ss',
      allowEdit: false,
      allowSort: false,
      cellsWrap: true,
    },{ 
      label: '', 
      dataField: 'resetPassword',
      align: 'left',
      width: 240,
      allowEdit: false,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => 
      {
        formatObject.template = GridCellResetPassword({
          index: formatObject.row.index,
          pageSize: pageSize,
          userName: isValidJSON( formatObject.value ) ? 
            JSON.parse( formatObject.value ).firstName 
            + ' ' 
            + JSON.parse( formatObject.value ).lastName
            : null,
          lastRequestPasswordDatetime: isValidJSON( formatObject.value ) ?
            JSON.parse( formatObject.value ).lastRequestPasswordDatetime:
            null,
          confirmCallBackFct: () => isValidJSON( formatObject.value ) ?
            resetPassword( 
              JSON.parse( formatObject.value ).userId,
              JSON.parse( formatObject.value ).firstName,
              JSON.parse( formatObject.value ).lastName,
              JSON.parse( formatObject.value ).email
            )
            : null
        });
      }
    },{ 
      label: '', 
      dataField: 'remove',
      align: 'left',
      width: 54,
      allowEdit: false,
      allowSort: false,
      cellsWrap: true,
      template: formatObject => 
      {
        if(
          isValidJSON( formatObject.value )
          && JSON.parse( formatObject.value ).userId !== parseInt( userId )
        ){
          formatObject.template = GridCellRemove({
            index: formatObject.row.index,
            pageSize: pageSize,
            areYouSureText: 
              'Êtes vous sûr de vouloir supprimer l\'utilisateur "' 
              + JSON.parse( formatObject.value ).name 
              + '" de l\'instance "' 
              + instanceDatas.label 
              + '" ?',
            confirmCallBackFct: () => delUser( JSON.parse( formatObject.value ).userId )
          });          
        } else {
          formatObject.template = '';
        }
      }
    }
  ];

  /** Format data source for Grid component */
  const formatDataSourceFct = response => response.map( elem => ({ 

    // user id
    userId: elem.id,

    // picture
    picture: JSON.stringify({
      picture: elem.picture,
      firstName: elem.firstName,
      lastName: elem.lastName
    }),

    // name
    firstName: elem.firstName,

    // surname
    lastName: elem.lastName,

    // email
    email: elem.email,

    // role
    role: elem.roleId,

    // last login date
    lastLoginDate: elem.lastConnectDatetime,

    // password
    resetPassword: JSON.stringify({
      userId: elem.id,
      firstName: elem.firstName,
      lastName: elem.lastName,
      email: elem.email,
      lastRequestPasswordDatetime: elem.lastRequestPasswordDatetime
    }),

    // remove
    remove: JSON.stringify({
      userId: elem.id,
      name: elem.firstName + ' ' + elem.lastName
    })
  }));

  const updateFirstName = ( userId, value ) => 
  {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).update( 
      userId,
      value,
      null,
      null,
      null,
      null,
      null,
      null,
      'reloadGrid'
    );
  }

  const updateLastName = ( userId, value ) => 
  {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).update( 
      userId,
      null,
      value,
      null,
      null,
      null,
      null,
      null,
      'reloadGrid'
    );
  }

  const updateEmail = ( userId, value ) => 
  {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).update( 
      userId,
      null,
      null,
      value,
      null,
      null,
      null,
      null,
      'getResults'
    );
  }

  const updateRole = ( userId, value ) => 
  {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).update( 
      userId,
      null,
      null,
      null,
      value,
      null,
      null,
      null,
      'reloadGrid'
    );
  }

  const delUser = userId => 
  {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).del( 
      userId,
      'reloadGrid'
    );
  }

  const resetPassword = (
    userId,
    firstName,
    lastName,
    email
  ) => {
    new wsUsers(
      'grid-loader-admin-users',
      dispatch,
      loadingParams.clientID
    ).resetPassword( 
      userId,
      firstName,
      lastName,
      email,
      'reloadGrid'
    );
  }

  useEffect( () => 
  {
    if( !isNullOrUndefined( reloadTrigger ) )
      setReloadTriggerGrid( reloadTrigger );

  }, [ reloadTrigger ]);
  
  return(
    <GridCore
      coreType='admin-users'
      gridColumns={ gridColumns }
      dataFields={ dataFields }
      loadingParams={{
        clientID: loadingParams.clientID,
        where: {
          roleId: roleId
        }
      }}
      displayMode='full-grid'
      formatDataSourceFct={ formatDataSourceFct }
      pageSize={ pageSize }
      editing={ true }
      rowHeight={ 57 }
      columnHeight={ 72 }
      pagerHeight={ 50 }
      getResultsFct={ results => 
      {
        // get error and set red background
        if( !isNullOrUndefined( results?.error ) && results.error === 'email' )
        {
          // get all email input
          const inputs = document.querySelectorAll( 'smart-grid-cell[data-field="email"]>div[data-field="email"]' );

          // set red background to which one match error email
          inputs.forEach( input => input.innerText === results.email ? input.classList.add( 'invalid' ) : null )

        } else
          setReloadTriggerGrid( Math.ceil( Math.random() * 1000 ) );
      }}
      onEndEditFct={ e => 
      {
        if( 
          !isNullOrUndefined(  e.detail.cell.value )
          && !isNullOrUndefined(  e.detail.cell.oldValue )
          && e.detail.cell.value !== e.detail.cell.oldValue 
        ){
          // choose action to call with datafield updated name
          switch ( e.detail.dataField ) 
          {            
            case 'firstName':
              updateFirstName( e.detail.data.userId, e.detail.cell.value );    
              break;

            case 'lastName':
              updateLastName( e.detail.data.userId, e.detail.cell.value );    
              break;

            case 'email':
              updateEmail( e.detail.data.userId, e.detail.cell.value );
              break;

            default: void(0); 
              break;
          }
          
          // Update cell value with new label
          e.detail.cell.oldValue = e.detail.cell.value;
        }
      }}
      callBackFct={ results => typeof callBackFct === 'function' ? callBackFct( results ) : void(0) }
      reloadTrigger={ reloadTriggerGrid }
    />
  );
}

export default GridCoreAdminUsers;