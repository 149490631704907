/** Dependencies */
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

/** Components */
import GridCoreAdminUsers from "./../../Grid/Core/AdminUsers/GridCoreAdminUsers";

/** Helpers */
import { getInstanceDatas } from './../../../helpers/instance';
import { isNullOrUndefined } from '../../../helpers/functions';

function Users( props )
{
  const {
    callBackFct,
    reloadTrigger
  } = props;

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;
  
  return(
    <div>

      {/* Grid */}
      <GridCoreAdminUsers
        loadingParams={{
          clientID: clientID,
          device: device,
          location: location + '|' + city,
        }}
        reloadTrigger={ reloadTrigger }
        callBackFct={ results => (
            typeof callBackFct === 'function' 
            && !isNullOrUndefined( results?.nonFilteredDataSource )
          ) ? 
            callBackFct( results.nonFilteredDataSource ) 
            : void(0) 
        }
      />

    </div>
  )
}

export default Users;