/** Dependencies */
import { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Input from 'smart-webcomponents-react/input';
import WindowButton from '../../WindowButton/WindowButton';

/** Components */
import Loader from './../../Loader/Loader';
import AreYouSure from './../../AreYouSure/AreYouSure';
import CategoriesAddWindow from './CategoriesAddWindow/CategoriesAddWindow';

/** Helpers */
import { isNullOrUndefined } from './../../../helpers/functions';
import { getInstanceDatas } from './../../../helpers/instance';
import { getPicto } from './../../../helpers/pictos';

/** Object class */
import wsCategories from './../../../helpers/webservice/wsCategories.class';

/** SCSS */
import './Categories.scss'

function Categories()
{
  /** useDispatch hook **/
  const dispatch = useDispatch();

  /** userRef Hook */
  const listBoxRef = useRef( null )

  /** useState Hook */
  const [ categories, setCategories ] = useState( null );
  const [ categoriesFilter, setCategoriesFilter ] = useState( null );
  const [ categories2, setCategories2 ] = useState( null );
  const [ categories2Filter, setCategories2Filter ] = useState( null );
  const [ categories3, setCategories3 ] = useState( null );
  const [ categories3Filter, setCategories3Filter ] = useState( null );
  const [ currentCat1, setCurrentCat1 ] = useState( null );
  const [ currentCat2, setCurrentCat2 ] = useState( null );
  const [ reloadTrigger, setReloadTrigger ] = useState( null );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );
  
  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  // Define colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Display sub categories */
  const displaySubCat = ( label, level ) =>  
  {
    if( level === 1 ){
      setCurrentCat1( label );
      setCurrentCat2( null );
      setCategories2( categories[label][ 'cat' + ( level + 1 ) ] );
      setCategories3( null );
    } else if( level === 2 ) {
      setCurrentCat2( label );
      setCategories3( categories2[label][ 'cat' + ( level + 1 ) ] );
    }
  }

  /** Display hidden update field */
  const displayUpdateField = ( index, level ) => 
  {
    // get input field
    const input = document.getElementById( 'category-editor-cat' + ( level + 1 ) + '-' + index );

    // show input field
    input.classList.remove( 'hide' );

    // focus on input field
    input.focus();
  }

  /** Reset update field properties */
  const resetUpdateField = ( index, level, oldValue) => 
  {
    // get input field
    const input = document.getElementById( 'category-editor-cat' + ( level + 1 ) + '-' + index );

    // remove invalid class
    input.classList.remove( 'invalid' );

    // add hide class
    input.classList.add( 'hide' );

    // set input value with old value
    input.value = oldValue;
  }

  /** Update category */
  const updateCategory = ( categoryId, label, existingCategories ) => 
  {
    if( 
      typeof label === 'string'
      && label.trim() !== '' 
      && !existingCategories.includes( label )
    ){
      new wsCategories(
        'admin-categories-loader',
        device,
        [ location + '|' + city ],
        dispatch,
        clientID
      ).update( 
        categoryId,
        label,
        'setReloadTrigger'
      );
    }
  }

  /** Remove category */
  const removeCategory = ( category, level ) => 
  {
    let removeIds = [];
    if( level === 0 )
    {
      // set array with id to removed at level 0
      removeIds = [ categories[category].id ];

      // set array with id to removed at level 1
      const categories2 = categories[category].cat2;
      Object.keys( categories2 ).forEach( values => 
      {
        const categories3 = categories2[values].cat3;
        removeIds = [ ...removeIds, categories2[values].id ];

        // set array with id to removed at level 2
        Object.keys( categories3 ).forEach( values =>
        {
          removeIds = [ ...removeIds, categories3[values].id ];
        });
      });

      // reset current categories
      setCurrentCat1( null );
      setCurrentCat2( null );

    } else if( level === 1 ) {

      // set array with id to removed at level 1
      removeIds = [ categories2[category].id ];

      // set array with id to removed at level 2
      const categories3 = categories2[category].cat3;      
      Object.keys( categories3 ).forEach( values =>
      {
        removeIds = [ ...removeIds, categories3[values].id ];
      });

      // reset current categories
      setCurrentCat2( null );

    } else if( level === 2 ) {

      // set array with id to removed at level 2
      removeIds = [ categories3[category].id ];
    }

    new wsCategories(
      'admin-categories-loader',
      device,
      [ location + '|' + city ],
      dispatch,
      clientID
    ).del( 
      removeIds,
      'setReloadTrigger'
    );
  }

  /** Load Categories */
  useEffect( () => 
  {
    new wsCategories(
      'admin-categories-loader',
      device,
      [ location + '|' + city ],
      dispatch,
      clientID
    ).get( 
      'setCategories'
    );

  }, [ 
    location,
    reloadTrigger
  ]);

  useEffect( () => 
  {
    // reset categorie2 after reloard categorie if currentCat1 exist
    if( currentCat1 && !isNullOrUndefined( categories[currentCat1] ) )
      setCategories2( categories[currentCat1][ 'cat2' ] );
    else
      setCategories2( null );

  }, [ categories ]);

  useEffect( () => 
  {
    // reset categorie3 after reloard categorie2 if currentCat2 exist
    if( currentCat2 && !isNullOrUndefined( categories2[currentCat2] ) )
      setCategories3( categories2[currentCat2][ 'cat3' ] );
    else
      setCategories3( null );

  }, [ categories2 ]);

  return(
    <div className="admin-content-container">

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-categories-loader' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          setCategories: results => setCategories( results ),
          setReloadTrigger: () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) )
        }}
      />

      {/* Cat 1 */}
      <div className="header">
        <h4>Catégories</h4>
        <h4>Mots-clés</h4>
      </div>
      <div className="listbox" ref={ listBoxRef }>
        <div className="listbox-header">
          <Input 
            className='category-filter' 
            onChanging={ e => setCategoriesFilter( e.detail.value ) }
            value={ isNullOrUndefined( categoriesFilter ) ? '' : categoriesFilter }
          />
          <div className='magnifying-glass'>
            { getPicto( 'MagnifyingGlass', { size: "1.2rem", weight: 'bold', color: grey2Color } ) }
          </div>
          <WindowButton
            id={ 'add-cat1' }
            className="add-cat-button"
            windowContent={
              <CategoriesAddWindow 
                title={ 'Nouvelles catégories' }
                existingCategories={ !isNullOrUndefined( categories ) ? Object.keys( categories ) : null }
                level={ 0 }
                parentId={ 'null' }
                callBackFct={ () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) ) }
              />
            }
            width={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetWidth - 16 : 0 }
            height={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetHeight - 64 : 0 }
            buttonContent={ getPicto( 'Plus', { size: "1.5rem", color: grey2Color } ) }
            activeEffect="horizontalFlip"
          />
        </div>
        <ul className="listbox-content">
          {
            !isNullOrUndefined( categories )
            && Object.keys( categories ).length > 0 ?
              Object.keys( categories )
              .filter( category => !isNullOrUndefined( categoriesFilter ) ? category.includes( categoriesFilter ) : true )
              .sort()
              .map( ( category, index ) => 
                <li
                  key={ index }
                  className={ currentCat1 === category ? 'selected' : '' }
                  onClick={ () => displaySubCat( category, 1 ) }
                  onDoubleClick={ () =>displayUpdateField( index, 0 ) }                  
                >
                  <div className="col1">
                    <Input 
                      id={ 'category-editor-cat1-' + index }
                      className='category-editor hide'
                      value={ category }
                      onChanging={ e => Object.keys( categories ).filter( cat => cat !== category).includes( e.detail.value ) ? 
                        document.getElementById( 'category-editor-cat1-' + index ).classList.add( 'invalid' ) 
                        : document.getElementById( 'category-editor-cat1-' + index ).classList.remove( 'invalid' ) 
                      }
                      onChange={ e => updateCategory( 
                        categories[category].id, 
                        e.target.value, 
                        Object.keys( categories ).filter( cat => cat !== category) ) 
                      }
                      onBlur={ () => resetUpdateField( index, 0, category ) }
                    />
                    { category }
                  </div>
                  <div className="col2">
                    <div className='col2_1'>{ categories[category].totalKeywords }</div>
                    <div className="col2_2">
                      <WindowButton
                        id={ 'remove-cat1-' + index }
                        className="remove-cat-button"
                        windowContent={
                          <AreYouSure 
                            text={ 'Êtes vous sûr de vouloir supprimer cette catégorie ainsi que toutes les sous catégories associées ?' }
                            confirmCallBackFct={ () => removeCategory( category, 0 )  }
                            isOpened={ true }
                          />
                        }
                        buttonContent={ getPicto( 'Trash', { size: "1.4rem", color: grey2Color } ) }
                        width={ 250 }
                        height={ 160 }
                        activeEffect="horizontalFlip"
                      />
                      <div className='caret-right'>{ getPicto( 'CaretRight', { size: "1.4rem", weight: 'bold', color: grey2Color } ) }</div>
                    </div>
                  </div>            
                </li>
              )
              : void( 0 )
          }
        </ul>        
      </div>

      {/* Cat 2 */}
      <div className="header">
        <h4>Sous Catégories</h4>
        <h4>Mots-clés</h4>
      </div>
      <div className="listbox">
        <div className="listbox-header">
          {
            !isNullOrUndefined( currentCat1 ) ?
              <Fragment>
                <Input 
                  className='category-filter' 
                  onChanging={ e => setCategories2Filter( e.detail.value ) }
                  value={ isNullOrUndefined( categories2Filter ) ? '' : categories2Filter }
                />
                <div className='magnifying-glass'>
                  { getPicto( 'MagnifyingGlass', { size: "1.2rem", weight: 'bold', color: grey2Color } ) }
                </div>
                <WindowButton
                  id={ 'add-cat2' }
                  className="add-cat-button"
                  windowContent={
                    <CategoriesAddWindow 
                      title={ 'Nouvelles sous catégories' }
                      existingCategories={ !isNullOrUndefined( categories[currentCat1]?.cat2 ) ? 
                        Object.keys( categories[currentCat1].cat2 ) 
                        : null 
                      }
                      level={ 1 }
                      parentId={ !isNullOrUndefined( categories[currentCat1]?.id ) ? 
                        categories[currentCat1].id : 
                        null   
                      }
                      callBackFct={ () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) ) }
                    />
                  }
                  width={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetWidth - 16 : 0 }
                  height={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetHeight - 64 : 0 }
                  buttonContent={ getPicto( 'Plus', { size: "1.5rem", color: grey2Color } ) }
                  activeEffect="horizontalFlip"
                />
              </Fragment>
              : void( 0 )
          }          
        </div>
        <ul className="listbox-content">
          {
            !isNullOrUndefined( categories2 )
            && Object.keys( categories2 ).length > 0 ?
              Object.keys( categories2 )
              .filter( category => !isNullOrUndefined( categories2Filter ) ? category.includes( categories2Filter ) : true )
              .sort()
              .map( ( category, index ) => 
                <li
                  key={ index }
                  className={ currentCat2 === category ? 'selected' : '' }
                  onClick={ () => displaySubCat( category, 2 ) }
                  onDoubleClick={ () =>displayUpdateField( index, 1 ) }
                >
                  <div className="col1">
                    <Input 
                      id={ 'category-editor-cat2-' + index }
                      className='category-editor hide'
                      value={ category }
                      onChanging={ e => Object.keys( categories2 ).filter( cat => cat !== category).includes( e.detail.value ) ? 
                        document.getElementById( 'category-editor-cat2-' + index ).classList.add( 'invalid' ) 
                        : document.getElementById( 'category-editor-cat2-' + index ).classList.remove( 'invalid' ) 
                      }
                      onChange={ e => updateCategory( 
                        categories2[category].id, 
                        e.target.value, 
                        Object.keys( categories2 ).filter( cat => cat !== category) ) 
                      }
                      onBlur={ () => resetUpdateField( index, 1, category ) }
                    />
                    { category }
                  </div>            
                  <div className="col2">
                    <div className='col2_1'>{ categories2[category].totalKeywords }</div>
                    <div className="col2_2">
                      <WindowButton
                        id={ 'remove-cat2-' + index }
                        className="remove-cat-button"
                        windowContent={
                          <AreYouSure 
                            text={ 'Êtes vous sûr de vouloir supprimer cette catégorie ainsi que toutes les sous catégories associées ?' }
                            confirmCallBackFct={ () => removeCategory( category, 1 )  }
                            isOpened={ true }
                          />
                        }
                        buttonContent={ getPicto( 'Trash', { size: "1.4rem", color: grey2Color } ) }
                        width={ 250 }
                        height={ 160 }
                        activeEffect="horizontalFlip"
                      />
                      <div className='caret-right'>{ getPicto( 'CaretRight', { size: "1.4rem", weight: 'bold', color: grey2Color } ) }</div>
                    </div>
                  </div>            
                </li>
              )
              : void( 0 )
          }
        </ul>        
      </div>

      {/* Cat 3 */}
      <div className="header">
        <h4>Sous sous Catégories</h4>
        <h4>Mots-clés</h4>
      </div>
      <div className="listbox">
        <div className="listbox-header">
        {
          !isNullOrUndefined( currentCat2 ) ?
            <Fragment>
              <Input 
                className='category-filter' 
                onChanging={ e => setCategories3Filter( e.detail.value ) }
                value={ isNullOrUndefined( categories3Filter ) ? '' : categories3Filter }
              />
              <div className='magnifying-glass'>
                { getPicto( 'MagnifyingGlass', { size: "1.2rem", weight: 'bold', color: grey2Color } ) }
              </div>
              <WindowButton
                id={ 'add-cat3' }
                className="add-cat-button"
                windowContent={
                  <CategoriesAddWindow 
                    title={ 'Nouvelles sous sous catégories' }
                    existingCategories={ !isNullOrUndefined( categories2[currentCat2]?.cat3 ) ? 
                      Object.keys( categories2[currentCat2].cat3 ) 
                      : null 
                    }
                    level={ 2 }
                    parentId={ !isNullOrUndefined( categories2[currentCat2]?.id ) ? 
                      categories2[currentCat2].id 
                      : null   
                    }
                    callBackFct={ () => setReloadTrigger( Math.ceil( Math.random() * 1000 ) ) }
                  />
                }
                width={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetWidth - 16 : 0 }
                height={ !isNullOrUndefined( listBoxRef.current ) ? listBoxRef.current.offsetHeight - 64 : 0 }
                buttonContent={ getPicto( 'Plus', { size: "1.5rem", color: grey2Color } ) }
                activeEffect="horizontalFlip"
              />
            </Fragment>
            : void( 0 )
        }
        </div>
        <ul className="listbox-content">
          {
            !isNullOrUndefined( categories3 )
            && Object.keys( categories3 ).length > 0 ?
              Object.keys( categories3 )
              .filter( category => !isNullOrUndefined( categories3Filter ) ? category.includes( categories3Filter ) : true )
              .sort()
              .map( ( category, index ) => 
                <li
                  key={ index }
                  onDoubleClick={ () =>displayUpdateField( index, 2 ) }
                >
                  <div className="col1">
                    <Input 
                      id={ 'category-editor-cat3-' + index }
                      className='category-editor hide'
                      value={ category }
                      onChanging={ e => Object.keys( categories3 ).filter( cat => cat !== category).includes( e.detail.value ) ? 
                        document.getElementById( 'category-editor-cat3-' + index ).classList.add( 'invalid' ) 
                        : document.getElementById( 'category-editor-cat3-' + index ).classList.remove( 'invalid' ) 
                      }
                      onChange={ e => updateCategory( 
                        categories3[category].id, 
                        e.target.value, 
                        Object.keys( categories3 ).filter( cat => cat !== category) ) 
                      }
                      onBlur={ () => resetUpdateField( index, 2, category ) }
                    />
                    { category }  
                  </div>            
                  <div className="col2">
                    <div className='col2_1'>{ categories3[category].totalKeywords }</div>
                    <div className="col2_2">
                      <WindowButton
                        id={ 'remove-cat3-' + index }
                        className="remove-cat-button"
                        windowContent={
                          <AreYouSure 
                            text={ 'Êtes vous sûr de vouloir supprimer cette catégorie ?' }
                            confirmCallBackFct={ () => removeCategory( category, 2 )  }                            
                            isOpened={ true }
                          />
                        }
                        buttonContent={ getPicto( 'Trash', { size: "1.4rem", color: grey2Color } ) }
                        width={ 250 }
                        height={ 160 }
                        activeEffect="horizontalFlip"
                      />
                    </div>
                  </div>            
                </li>
              )
              : void( 0 )
            }
        </ul>        
      </div>
    </div>
  )
}

export default Categories;