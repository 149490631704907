/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsCategories
{
  constructor(
    loaderID,
    devices,
    locations,
    dispatch,
    selectedInstance
  ){
    this.devices = devices;
    this.locations = locations;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  get = ( 
    callBackFct = null
  ) => 
  {
    this.exec(
      'get-categories',
      null,
      null,
      null,
      null,
      callBackFct
    );
  }

  add = ( 
    labels, 
    level, 
    parentId,
    callBackFct = null
  ) => 
  {
    this.exec(
      'add-categories',
      null,
      labels, 
      level, 
      parentId,
      callBackFct
    );
  }
  
  update = (
    categoryId, 
    newLabel,
    callBackFct = null
  ) => 
  {
    this.exec(
      'update-categorie',
      categoryId, 
      newLabel,
      null,
      null,
      callBackFct
    );
  }

  del = (
    categoriesIds,
    callBackFct = null
  ) => 
  {
    this.exec(
      'del-categorie',
      categoriesIds,
      null,
      null,
      null,
      callBackFct
    );
  }
  
  exec = (
    serviceName,
    categoriesIds,
    labels,
    level,
    parentId,
    callBackFct = null
  ) => 
  {
    callWebservice( 
      this.loaderID,
      serviceName,
      serviceName,
      this.dispatch,
      this.selectedInstance,
      {
        categoryId: Array.isArray( categoriesIds ) && categoriesIds[0] ? categoriesIds[0] : categoriesIds,
        categoriesIds: categoriesIds,
        label: Array.isArray( labels ) && labels[0] ? labels[0] : labels,
        labels: labels,
        levelCategory: level,
        parentCategoryId: parentId,
        location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
        where: {
          device: Array.isArray( this.devices ) && this.devices[0] ? this.devices[0] : this.devices,
          devices: Array.isArray( this.devices ) ? this.devices : [ this.devices ],
          location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
          locations: Array.isArray( this.locations ) ? this.locations : [ this.locations ]
        }
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}