/** Dependencies */
import { Fragment } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import moment from 'moment';

/** Redux */
import store from './../../../store';

/** Components */
import WindowButton from './../../WindowButton/WindowButton';
import AreYouSure from './../../AreYouSure/AreYouSure';

/** Helpers */
import { isNullOrUndefined } from './../../../helpers/functions';

/** SCSS */
import './GridCellResetPassword.scss';

function GridCellResetPassword( props )
{
  const {
    index,
    pageSize,
    userName,
    lastRequestPasswordDatetime,
    confirmCallBackFct
  } = props;

  // create html element wrapper
  const rootElement = document.createElement( 'div' );

  // add class
  rootElement.className = 'reset-password-wrapper';

  // create root element to append new div element
  const root = createRoot( rootElement );

  // render element
  root.render
  (    
    <Provider store={ store }>
      <WindowButton
        id={ 'reset-password-' + index }
        className="reset-password"
        windowContent={
          <AreYouSure 
            text={ 'Êtes vous sûr de vouloir réinitialiser le mot de passe de "' + userName + '" ?' }
            confirmCallBackFct={ confirmCallBackFct }
            isOpened={ true }
          />
        }
        activeEffect="horizontalFlip"
        buttonContent={ <Fragment>|<span>**</span></Fragment> }
        mouseEventType="click"
        positionClassName={ ( ( index + 1 ) / pageSize ) % 1 > 0.75 || Number.isInteger( ( index + 1 ) / pageSize ) ? 'bottom' : 'top' }
      />
      {
        isNullOrUndefined( lastRequestPasswordDatetime ) ?
          <p className='reset-password-label'>Réinitialiser mot de passe</p>
        : moment( lastRequestPasswordDatetime ).add( 4, 'h' ).isAfter( moment() ) ? 
          <p className='reset-password-label'>Réinitialisation en cours</p>
        : <p className='reset-password-label'>Réinitialisation à renouveler</p>          
      }
      
    </Provider>
  );

  return rootElement;
} 

export default GridCellResetPassword;