/** Dependencies */
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Input from 'smart-webcomponents-react/input';
import Button from 'smart-webcomponents-react/button';

/** Components */
import Loader from './../../../Loader/Loader';

/** Helpers */
import { getInstanceDatas } from './../../../../helpers/instance';
import { isNullOrUndefined } from './../../../../helpers/functions';

/** Object class */
import wsCategories from './../../../../helpers/webservice/wsCategories.class';

/** SCSS */
import './CategoriesAddWindow.scss'

function CategoriesAddWindow( props )
{
  const {
    title,
    existingCategories,
    level,
    parentId,
    callBackFct,
    openCloseToggler // use for WindowButton component
  } = props;

  /** useDispatch hook **/
  const dispatch = useDispatch();

  /** useState Hook */
  const [ newCategories, setNewCategories ] = useState( {} );

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );
  
  /** Get instance infos from url */
  const { clientID, device, location } = useParams();

  /** Get city from userDatas */
  let city = null;
  if( Object.keys( getInstanceDatas( clientID, userDatas ) ).length > 0 )
    city = getInstanceDatas( clientID, userDatas ).devLoc.filter( devLoc => devLoc.countryValue === location )[0].cityValue;

  // Define colors
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();

  /** Check if value is valid */
  const isValidValue = ( inputId, value ) => 
  {
    if(
      Object.values( newCategories ).includes( value )
      || existingCategories.find( category => category === value ) !== undefined
    )
      document.getElementById( inputId ).classList.add( 'invalid' );
    else
      document.getElementById( inputId ).classList.remove( 'invalid' );
  }

  /** Add category */
  const addCategories = () => 
  {
    // get distinct new categories
    const newCategoriesValues = [ ...new Set( Object.values( newCategories ) )].filter( value => !isNullOrUndefined( value ));

    if( 
      newCategoriesValues.length > 0 
      && existingCategories.filter( 
        value => newCategoriesValues.includes( value ) 
      ).length === 0
      && !isNullOrUndefined( level )
      && !isNullOrUndefined( parentId )
    ){
      new wsCategories(
        'admin-add-categories-loader',
        device,
        [ location + '|' + city ],
        dispatch,
        clientID
      ).add( 
        newCategoriesValues,
        level,
        parentId,
        'callBackFct'
      );      
    }
  }

  return(
    <div className='add-cat-window'>

      {/* Loader */}
      <Loader 
        loaderID={ 'admin-add-categories-loader' }
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
          callBackFct: results => 
          {
            // call callBackFct
            if( typeof callBackFct === 'function' )
              callBackFct( results );

            // close window
            if( typeof openCloseToggler === 'function' )
              openCloseToggler();
          }
        }}
      />

      <h4>{ title }</h4>
      <Input 
        id={ 'input-category1' }
        onChange={ e => setNewCategories({ ...newCategories, category1: e.target.value.trim() === '' ? null : e.target.value }) }
        onChanging={ e => isValidValue( 'input-category1', e.detail.value ) }
        value={ isNullOrUndefined( newCategories.category1 ) ? '' : newCategories.category1 }
      />
      <Input 
        id={ 'input-category2' }
        onChange={ e => setNewCategories({ ...newCategories, category2: e.target.value.trim() === '' ? null : e.target.value }) }
        onChanging={ e => isValidValue( 'input-category2', e.detail.value ) }
        value={ isNullOrUndefined( newCategories.category2 ) ? '' : newCategories.category2 }
      />
      <Input 
        id={ 'input-category3' }
        onChange={ e => setNewCategories({ ...newCategories, category3: e.target.value.trim() === '' ? null : e.target.value }) }
        onChanging={ e => isValidValue( 'input-category3', e.detail.value ) }
        value={ isNullOrUndefined( newCategories.category3 ) ? '' : newCategories.category3 }
      />
      <Input 
        id={ 'input-category4' }
        onChange={ e => setNewCategories({ ...newCategories, category4: e.target.value.trim() === '' ? null : e.target.value }) }
        onChanging={ e => isValidValue( 'input-category4', e.detail.value ) }
        value={ isNullOrUndefined( newCategories.category4 ) ? '' : newCategories.category4 }
      />
      <Input 
        id={ 'input-category5' }
        onChange={ e => setNewCategories({ ...newCategories, category5: e.target.value.trim() === '' ? null : e.target.value }) }
        onChanging={ e => isValidValue( 'input-category5', e.detail.value ) }
        value={ isNullOrUndefined( newCategories.category5 ) ? '' : newCategories.category5 }
      />
      <div className='confirm-container' id='admin-add-keywords-confirm-container'>
        <Button 
          className='flat empty'            
          onClick={ () => typeof openCloseToggler === 'function' ? openCloseToggler() : null }
        >Annuler</Button>

        <Button 
          className='flat fill' 
          onClick={ addCategories }
        >Ajouter</Button>
      </div>
    </div>
  )
}

export default CategoriesAddWindow;