/** Helpers **/
import { callWebservice } from './webserviceCaller';

export default class wsKeywords
{
  constructor(
    loaderID,
    devices,
    locations,
    dispatch,
    selectedInstance
  ){
    this.devices = devices;
    this.locations = locations;
    this.loaderID = loaderID;
    this.dispatch = dispatch;
    this.selectedInstance = selectedInstance;
  }

  get = (
    callBackFct = null
  ) => 
  {
    this.exec(
      'get-keywords',
      null, 
      null,
      null, 
      null,
      callBackFct
    );
  }

  add = ( 
    keywords, 
    categories, 
    extractSources,
    callBackFct = null
  ) => 
  {
    this.exec(
      'add-keywords',
      keywords, 
      null,
      categories, 
      extractSources,
      callBackFct
    );
  }
  
  update = (
    keywords, 
    newLabel = null,
    categories = null, 
    extractSources = null,
    callBackFct = null
  ) => 
  {
    this.exec(
      'update-keywords',
      keywords, 
      newLabel,
      categories, 
      extractSources,
      callBackFct
    );
  }
  
  /** Remove keyword from kwCategories */
  del = (
    keywords,
    callBackFct = null
  ) => 
  {
    this.exec(
      'delete-keywords',
      keywords, 
      null,
      null,
      null,
      callBackFct
    );
  }

  /** Update kwCategories with removedDate and extractSource='' */
  remove = (
    keywords,
    callBackFct = null
  ) => 
  {
    this.exec(
      'remove-keywords',
      keywords, 
      null,
      null,
      '',
      callBackFct
    );
  }
  
  exec = (
    serviceName,
    keywords, 
    newLabel,
    categories, 
    extractSources,
    callBackFct = null
  ) => 
  {
    callWebservice( 
      this.loaderID,
      serviceName,
      serviceName,
      this.dispatch,
      this.selectedInstance,
      {
        keywords: keywords, 
        label: newLabel,
        categories: categories,
        extractSources: extractSources,
        device: Array.isArray( this.devices ) && this.devices[0] ? this.devices[0] : this.devices,
        devices: Array.isArray( this.devices ) ? this.devices : [ this.devices ],
        location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
        locations: Array.isArray( this.locations ) ? this.locations : [ this.locations ],
        where: {
          device: Array.isArray( this.devices ) && this.devices[0] ? this.devices[0] : this.devices,
          devices: Array.isArray( this.devices ) ? this.devices : [ this.devices ],
          location: Array.isArray( this.locations ) && this.locations[0] ? this.locations[0] : this.locations,
          locations: Array.isArray( this.locations ) ? this.locations : [ this.locations ]
        }
      },
      callBackFct !== null ? {
        function: callBackFct
      } : null
    );
  }
}