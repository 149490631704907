/** Dependencies */
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath, useSearchParams, useLocation } from "react-router-dom";
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';

/** Components */
import WindowButton from './../../WindowButton/WindowButton';
import AdminMenuContextualAddKeywords from './../AdminMenuContextualContent/AdminMenuContextualAddKeywords';
import AdminMenuContextualMassActionsKeywords from './../AdminMenuContextualContent/AdminMenuContextualMassActionsKeywords'

/** Helpers */
import { getPicto } from './../../../helpers/pictos';
import { isNullOrUndefined } from './../../../helpers/functions';
import { 
  getInstanceDatas
} from './../../../helpers/instance';


/** SCSS */
import './AdminMenuContextualKeywords.scss';

function AdminMenuContextualKeywords( props )
{
  const {
    params,
    updateReloadTrigger,
  } = props;

  /** Instance useNavigate Hook */
  const navigate = useNavigate();

  /** Instance useLocation Hook */
  const { pathname } = useLocation();

  /** Instance useSearchParams Hook */
  let [ searchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID, location, device } = useParams();

  // get instance datas from user datas
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  return(
    <div className='contextual-options keywords'>

      {/* Select location buttons group */}
      <ButtonGroup  
        key={ location }
        dataSource={
          [...new Map( 
            instanceDatas.devLoc.map( devLoc => [ devLoc['countryValue'], devLoc ] ) ).values()
          ].map( devLoc => ( { 
              label: devLoc.countryLabel, 
              value: devLoc.countryValue 
          }))
        }
        selectedValues={[ location ]}
        onChange={ e => navigate({
          pathname: generatePath( 
            '/' + pathname.split( '/' )[1] +'/' + pathname.split( '/' )[2] + '/:clientID/:location/:device', 
            { clientID: clientID, location: e.detail.values[0], device: device }
          ),
          search: searchParams.toString()
        })} 
      />

      {/* Keywords add button */}
      <WindowButton 
        id="keywords-add-window"
        className="keywords-add-window"
        activeEffect="horizontalFlip"
        buttonContent={ getPicto( 'Plus', { size: '1.5rem', fill: grey2Color } ) }
        windowContent={ 
          <AdminMenuContextualAddKeywords 
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        }
        width={ window.innerWidth - 72 }
        height={ window.innerHeight - 144 }
      />

      {/* Keywords import button */}
      {/* <WindowButton 
        id="keywords-import-window"
        className="keywords-import-window"
        activeEffect="horizontalFlip"
        buttonContent={ getPicto( 'DownloadSimple', { size: '1.5rem', fill: grey2Color } ) }
        windowContent={ 
          <div>Import de mots-clés</div>
        }
        // width={ box2Width }
      /> */}

      {/* Mass action button */}
      <WindowButton 
        id="keywords-mass-action"
        className="keywords-mass-action"
        buttonClassName="flat fill mass-action"
        buttonContent={ 
          <Fragment>
            { getPicto( 'ArrowPlainDown', { size: '1.5rem', fill: 'white' } ) }
            <p className='checkboxes-sum'>{ !isNullOrUndefined( params?.selectedKeywords ) ? params.selectedKeywords.length : 0 }</p>
          </Fragment>
        }
        windowContent={ 
          <AdminMenuContextualMassActionsKeywords
            params={ params }
            updateReloadTrigger={ updateReloadTrigger }
          />
        }    
        disabled={(
            isNullOrUndefined( params?.selectedKeywords ) 
            || ( 
              !isNullOrUndefined( params?.selectedKeywords ) 
              && params.selectedKeywords.length === 0 
            )
          ) ? 
            true : false 
        }
      />

    </div>
  )
}

export default AdminMenuContextualKeywords;