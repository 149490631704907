/** Components */
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';

/** Component */
import Loader from './../Loader/Loader';
import ChartSelector from "./../ChartSelector/ChartSelector";
import InsightsCompareMultiLineChart from "../Insights/InsightsCompareMultiLineChart";

/** Helpers */
import { isNullOrUndefined, getMinMaxFromArrayObject } from "./../../helpers/functions";
import { callWebservice } from './../../helpers/webservice/webserviceCaller';
import { formatDatasForChart } from "../../helpers/datas";
import { getDateFromPeriod, getGranularityStatus } from'./../../helpers/dates'

/** SCSS */
import './ChartDisplayer.scss';

function ChartDisplayer( props )
{
	const {
		loadingParams
	} = props;

	/** Instance Dispatch Hook **/
	const dispatch = useDispatch();

	/** Init State */
	const [ currentGSCDatas, setCurrentGSCDatas ] = useState( null );
  const [ compareGSCDatas, setCompareGSCDatas ] = useState( null );
	const [ currentSERPDatas, setCurrentSERPDatas ] = useState( null );
  const [ compareSERPDatas, setCompareSERPDatas ] = useState( null );
  const [ chartDatas, setChartDatas ] = useState( null );
  const [ minMaxChartValues, setMinMaxChartValues ] = useState( null );
  const [ selectedCharts, setSelectedCharts ] = useState( null );
  const [ datasLoaded, setDatasLoaded ] = useState( null );

	/** Define colors */
  const blueColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue' ).trim();
  const blue2Color = getComputedStyle( document.documentElement ).getPropertyValue( '--color-blue2' ).trim();
  const orangeColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-orange' ).trim();
  const yellowColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-yellow' ).trim();
	const gridLinesColor = getComputedStyle( document.documentElement ).getPropertyValue( '--color-grey3' ).trim();

	/** Load chart datas */
	useEffect( () => 
	{
		if(
			!isNullOrUndefined( loadingParams?.clientID )
			&& !isNullOrUndefined( loadingParams?.device )
			&& !isNullOrUndefined( loadingParams?.location )
			&& !isNullOrUndefined( loadingParams?.currentPeriod )
			&& !isNullOrUndefined( loadingParams?.comparePeriod )
			&& !isNullOrUndefined( loadingParams?.keywords )
			&& !isNullOrUndefined( loadingParams?.gridComponentLoading )
			&& loadingParams.gridComponentLoading === false
		){
			// get date from current and compare periods
			const dateFromCurrentPeriod = getDateFromPeriod( loadingParams.currentPeriod, null, 'YYYYMMDD' );
			const dateFromComparePeriod = getDateFromPeriod( loadingParams.currentPeriod, loadingParams.comparePeriod, 'YYYYMMDD' );

			// get granularityStatus from current dates
			const granularityStatus = getGranularityStatus( loadingParams.currentPeriod, loadingParams.comparePeriod );

			// load GSC for current period
			callWebservice(
				'chart-datas-loader',
				'chart-datas-loader',
				'gsc-trend',
				dispatch,
				loadingParams.clientID,
				{
					period: dateFromCurrentPeriod,
					periodGranularity: granularityStatus,
					where: {						
						keywords: loadingParams.keywords,
						locations: [ loadingParams.location ],
						devices: [ loadingParams.device ]
					}
				},
				{ function: 'setCurrentGSCDatas' }
			);

			// load GSC for compare period
			callWebservice(
				'chart-datas-loader',
				'chart-datas-loader',
				'gsc-trend',
				dispatch,
				loadingParams.clientID,
				{
					period: dateFromComparePeriod,
					periodGranularity: granularityStatus,
					where: {
						keywords: loadingParams.keywords,
						locations: [ loadingParams.location ],
						devices: [ loadingParams.device ]
					}
				},
				{ function: 'setCompareGSCDatas' }
			);

			// load GSC for current period
			callWebservice(
				'chart-datas-loader',
				'chart-datas-loader',
				'serp-trend-avgpos',
				dispatch,
				loadingParams.clientID,
				{
					period: dateFromCurrentPeriod,
					periodGranularity: granularityStatus,
					where: {
						keywords: loadingParams.keywords,
						locations: [ loadingParams.location ],
						devices: [ loadingParams.device ],
						who: ['me']
					}
				},
				{ function: 'setCurrentSERPDatas' }
			);

			// load GSC for compare period
			callWebservice(
				'chart-datas-loader',
				'chart-datas-loader',
				'serp-trend-avgpos',
				dispatch,
				loadingParams.clientID,
				{
					period: dateFromComparePeriod,
					periodGranularity: granularityStatus,
					where: {
						keywords: loadingParams.keywords,
						locations: [ loadingParams.location ],
						devices: [ loadingParams.device ],
						who: ['me']
					}
				},
				{ function: 'setCompareSERPDatas' }
			);
		}

	}, [ JSON.stringify( loadingParams ) ] );

	/** Format and set chart datas AND min max values */
	useEffect( () => 
	{
		if( 
			!isNullOrUndefined( currentGSCDatas )
			&& !isNullOrUndefined( compareGSCDatas )
			&& !isNullOrUndefined( compareSERPDatas )
			&& !isNullOrUndefined( compareSERPDatas )
			&& datasLoaded
		){
			// get granularityStatus from current dates
			const granularityStatus = getGranularityStatus( loadingParams.currentPeriod, loadingParams.comparePeriod );
			
			// remove currentGSCDatas.avgPosition
			delete( currentGSCDatas.avgPosition );
			delete( compareGSCDatas.avgPosition );

			// replace null values with 101 position
			const cloneCurrentSERPDatas = [ ...currentSERPDatas ].map( elem => ({ x: elem.x, y: isNullOrUndefined( elem.y ) ? 101 : elem.y }) );
			const cloneCompareSERPDatas = [ ...compareSERPDatas ].map( elem => ({ x: elem.x, y: isNullOrUndefined( elem.y ) ? 101 : elem.y }) );

			// replace GSC avgosition with SERP position
			currentGSCDatas.position = cloneCurrentSERPDatas;
			compareGSCDatas.position = cloneCompareSERPDatas;

			// format datas for chart
			const formatChartDatas = formatDatasForChart( currentGSCDatas, compareGSCDatas, granularityStatus );

			// set chart datas
			setChartDatas( formatChartDatas );
			
			// get and set min max chart values
			setMinMaxChartValues( 
				getMinMaxFromArrayObject( 
					formatChartDatas, 
					[
						'current-impressions',
						'compare-impressions',
						'current-clicks',
						'compare-clicks',
						'current-ctr',
						'compare-ctr',
						'current-position',
						'compare-position'
					] 
				)
			);

			// reste datasLoaded value
			setDatasLoaded( false );
		}

	}, [ datasLoaded ]);

  return(
    <div className="chart-displayer-wrapper">

			{/* Loader */}
			<Loader 
        loaderID='chart-datas-loader'
        loaderStyle={{
          width:'25', 
          stroke: blueColor, 
          viewBox:'-2 -2 42 42'
        }}
        callBackFcts={{
					setCurrentGSCDatas: results => setCurrentGSCDatas( results ),
					setCompareGSCDatas: results => setCompareGSCDatas( results ),
					setCurrentSERPDatas: results => setCurrentSERPDatas( results ),
					setCompareSERPDatas: results => setCompareSERPDatas( results )
				}}
				globalCallBack={ () => setDatasLoaded( true ) } 
				forceActiveLoader={ loadingParams.gridComponentLoading }
			/>

			{/* Chart Selector */}
      <ChartSelector
				chartDatas={ chartDatas }
				callBackFct={ setSelectedCharts }
			/>

			{/* Chart content */}
      <div className='chart-content'>
				{
					!isNullOrUndefined( chartDatas ) 
					&& !isNullOrUndefined( minMaxChartValues ) ?
						<InsightsCompareMultiLineChart 
							id='instance-keywords-chart'
							selectedSeriesGroup={ selectedCharts }
							datas={ chartDatas }
							dataField='xaxis'
							xAxisLabelVisible={ false }
							padding={ Object.values( selectedCharts ).filter( value => value ).length > 1 ? { left: 50 } : { left: 0, top: 5, right: 0, bottom: 5 } }
							seriesGroups={[{
								type: 'line',
								valueAxis: {
									axisSize: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.impressions ? 50 : 0,
									visible: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.impressions,
									minValue: Math.min( minMaxChartValues['current-impressions'].min, minMaxChartValues['compare-impressions'].min ) === 0 ? -0.1 : 0,
									maxValue: Math.max( minMaxChartValues['current-impressions'].max, minMaxChartValues['compare-impressions'].max ),
									formatFunction: value => value >= 0 ? Intl.NumberFormat('fr-FR', { notation: 'compact' }).format( value ) : '',
									gridLines: {
										dashStyle: '4,4',
										color: gridLinesColor
									}
								},
								series: [{ 
									dataField: "current-impressions",									
									fillColor: blueColor,
									lineColor: blueColor,
									lineWidth: 2,
									symbolType: 'circle',
									symbolSize: 10
								},{ 
									dataField: "compare-impressions",
									fillColor: blueColor,
									lineColor: blueColor,
									lineWidth: 2,
									dashStyle: '4,4',
									opacity: 0.8
								}]
							},{
								type: 'line',
								valueAxis: {
									axisSize: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.clicks ? 50 : 0,
									visible: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.clicks,
									minValue: Math.min( minMaxChartValues['current-clicks'].min, minMaxChartValues['compare-clicks'].min ) === 0 ? -0.1 : 0,
									maxValue: Math.max( minMaxChartValues['current-clicks'].max, minMaxChartValues['compare-clicks'].max ),
									formatFunction: value => value >= 0 ? Intl.NumberFormat('fr-FR', { notation: 'compact' }).format( value ) : '',
									gridLines: {
										dashStyle: '4,4',
										color: gridLinesColor
									}
								},
								series: [{ 
									dataField: "current-clicks",
									fillColor: orangeColor,
									lineColor: orangeColor,
									lineWidth: 2,
									symbolType: 'circle',
									symbolSize: 10
								},{ 
									dataField: "compare-clicks",
									fillColor: orangeColor,
									lineColor: orangeColor,
									lineWidth: 2,
									dashStyle: '4,4',
									opacity: 0.8
								}]
							},{
								type: 'line',
								valueAxis: {
									axisSize: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.ctr ? 50 : 0,
									visible: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.ctr,
									minValue: Math.min( minMaxChartValues['current-ctr'].min, minMaxChartValues['compare-ctr'].min ) === 0 ? -0.001 : 0,
									maxValue: Math.max( minMaxChartValues['current-ctr'].max, minMaxChartValues['compare-ctr'].max ),
									formatFunction: value => value >= 0 ? Intl.NumberFormat('fr-FR', { notation: 'compact', maximumFractionDigits: 1, style: 'percent' }).format( value ) : '',
									gridLines: {
										dashStyle: '4,4',
										color: gridLinesColor
									}
								},
								series: [{ 
									dataField: "current-ctr",
									fillColor: yellowColor,
									lineColor: yellowColor,
									lineWidth: 2,
									symbolType: 'circle',
									symbolSize: 10
								},{ 
									dataField: "compare-ctr",
									fillColor: yellowColor,
									lineColor: yellowColor,
									lineWidth: 2,
									dashStyle: '4,4',
									opacity: 0.8
								}]
							},{
								type: 'line',
								valueAxis: {
									axisSize: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.position ? 50 : 0,
									visible: Object.values( selectedCharts ).filter( value => value ).length === 1 && selectedCharts.position,
									minValue: 0,
									maxValue: 100,
									gridLines: {
										dashStyle: '4,4',
										color: gridLinesColor
									},
									flip: true
								},
								series: [{ 
									dataField: "current-position",
									colorFunction: ( value ) => 
									{	
										// set color
										let fillColor = blue2Color;
										if( value === 101 )
											fillColor = gridLinesColor;
			
										// return values
										return ({ 
											fillColor: fillColor, 
											lineColor: fillColor 
										});
									},
									lineWidth: 2,
									symbolType: 'circle',
									symbolSize: 10
								},{ 
									dataField: "compare-position",
									colorFunction: ( value ) => 
									{	
										// set color
										let fillColor = blue2Color;
										if( value === 101 )
											fillColor = gridLinesColor;
			
										// return values
										return ({ 
											fillColor: fillColor, 
											lineColor: fillColor 
										});
									},
									lineWidth: 2,
									dashStyle: '4,4',
									opacity: 0.8
								}]
							}
							]}
						/>
						: null
				}
      </div>              
    </div>
  )
}

export default ChartDisplayer;