/** Dependencies **/
import { addItem, removeItem } from "./LocaleStorage";

/**
 * Add all items from localstorage about login
 * @param {object} datas 
 * @returns void
 */
export const login = datas => 
{
  if( datas.response && datas.response === true )
  { 
    addItem( 'email', datas.data.email );
    addItem( 'userId', datas.data.id );
    addItem( 'firstName', datas.data.firstName );
    addItem( 'lastName', datas.data.lastName );
    addItem( 'connectToken', datas.data.connectToken );
  }
}

/**
 * Remove all items from localstorage about login
 */
export const logout = () => 
{
  removeItem( 'email' );
  removeItem( 'userId' );
  removeItem( 'firstName' );
  removeItem( 'lastName' );
  removeItem( 'connectToken' );
}