/** Dependencies */
import { useSelector } from 'react-redux';
import { useNavigate, useParams, generatePath, useSearchParams, useLocation } from "react-router-dom";
import { ButtonGroup } from 'smart-webcomponents-react/buttongroup';

/** Components */
import WindowButton from './../../WindowButton/WindowButton';

/** Helpers */
import { getPicto } from './../../../helpers/pictos';
import { 
  getInstanceDatas
} from './../../../helpers/instance';


/** SCSS */
import './AdminMenuContextualCategories.scss';

function AdminMenuContextualCategories( props )
{
  const {
    params,
    updateReloadTrigger,
  } = props;

  /** Instance useNavigate Hook */
  const navigate = useNavigate();

  /** Instance useLocation Hook */
  const { pathname } = useLocation();

  /** Instance useSearchParams Hook */
  let [ searchParams ] = useSearchParams();

  /** Get state from redux store **/
  const userDatas = useSelector( state => state.userDatas.value );

  // get instance infos from url
  const { clientID, location, device } = useParams();

  // get instance datas from user datas
  const instanceDatas = getInstanceDatas( clientID, userDatas );

  // Define increase and decrease colors
  const grey2Color = getComputedStyle( document.documentElement ).getPropertyValue('--color-grey2').trim();

  return(
    <div className='contextual-options categories'>

      {/* Select location buttons group */}
      <ButtonGroup  
        key={ location }
        dataSource={
          [...new Map( 
            instanceDatas.devLoc.map( devLoc => [ devLoc['countryValue'], devLoc ] ) ).values()
          ].map( devLoc => ( { 
              label: devLoc.countryLabel, 
              value: devLoc.countryValue 
          }))
        }
        selectedValues={[ location ]}
        onChange={ e => navigate({
          pathname: generatePath( 
            '/' + pathname.split( '/' )[1] +'/' + pathname.split( '/' )[2] + '/:clientID/:location/:device', 
            { clientID: clientID, location: e.detail.values[0], device: device }
          ),
          search: searchParams.toString()
        })} 
      />

      {/* Keywords import button */}
      {/* <WindowButton 
        id="keywords-import-window"
        className="keywords-import-window"
        activeEffect="horizontalFlip"
        buttonContent={ getPicto( 'DownloadSimple', { size: '1.5rem', fill: grey2Color } ) }
        windowContent={ 
          <div>Import de mots-clés</div>
        }
        // width={ box2Width }
      /> */}

    </div>
  )
}

export default AdminMenuContextualCategories;